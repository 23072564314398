<template>
  <div class="MonitorSettingsResponse">
    <ValidationObserver ref="observer" v-slot="{ passes }">
      <form class="MonitorForm"
            @submit.prevent="passes(onFormSubmit)">
        <base-textarea label="Response text"
                       ref="body"
                       description="We will verify that the response contains the given text"
                       rows="5"
                       v-model="response.body"/>
        <response-headers v-model="response.headers"/>

        <base-checkbox label="Not in response" v-model="response.is_negative"/>
        <div class="toolbar">
          <base-button ref="submit"
                       type="submit"
                       class="button-update submit success"
                       :loading="saving">Update
          </base-button>
        </div>
      </form>
    </ValidationObserver>

  </div>
</template>

<script>
import { cloneObject } from '@/services/utils'
import profileApi from '@/api/profileApi'
import ResponseHeaders from '@/components/Monitor/MonitorSettings/MonitorSettingsResponse/ResponseHeaders.vue'
import tippy from 'tippy.js'

export default {
  components: { ResponseHeaders },
  props: {
    settings: {
      type: Object
    }
  },

  data () {
    return {
      response: {
        body: '',
        headers: [],
        is_negative: 0
      },

      updateButtonTippy: null,
      saving: false
    }
  },

  created () {
    this.response = cloneObject(this.settings.response)
  },

  mounted () {
    this.$refs.body.focus()
  },

  methods: {
    async onFormSubmit () {
      this.updateButtonTippy = tippy(this.$refs.submit.$el, {
        content: 'Settings were updated successfully',
        arrow: false,
        placement: 'right',
        trigger: 'manual'
      })
      this.saving = true
      try {
        const response = await profileApi.saveMonitorGeneralResponseSettings({ response: this.response })
        this.triggerTippy()
        this.$emit('update', response)
      } catch (e) {
        this.showFailed()
      }
      this.saving = false
    },

    triggerTippy () {
      this.updateButtonTippy.show()

      setTimeout(() => {
        this.updateButtonTippy.hide()
      }, 2000)
    },

    showFailed () {
      this.updateButtonTippy = tippy(this.$refs.submit.$el, {
        content: 'There was an error',
        arrow: false,
        placement: 'right',
        trigger: 'manual'
      })

      this.updateButtonTippy.show()

      setTimeout(() => {
        this.updateButtonTippy.hide()
      }, 2000)
    }
  }
}
</script>

<style lang="scss" scoped>
.MonitorSettingsResponse {
  max-width: 550px;

  .toolbar {
    position: sticky;
    bottom:0;
    padding: 15px 0;
    backdrop-filter: blur(3px);

    margin-top: 20px;
  }
}
</style>
